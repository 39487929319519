







































































































































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
} from "@vue/composition-api"
import TimeText from "@/components/TimeText.vue"
import IssueLogTimeline from "./IssueLogTimeline.vue"
import UserChip from "@/components/UserChip.vue"
import SpaceObjectLocation from "@/components/SpaceObjectLocation.vue"
import IssueRelatedMission from "./IssueRelatedMission.vue"
import SendRepairMissionDialog from "./SendRepairMissionDialog.vue"
import SendCheckMissionDialog from "./SendCheckMissionDialog.vue"
import { title } from "@/services/AppBarService"
import {
    IssueViewModel,
    getIssue,
    issueStateFormatter,
    closeIssue as callCloseIssue,
    IssueState,
    AttachDTO,
    getIssueLogAttachFiles,
} from "@/services/IssueService"
import { timeFormatter, timeFromNowFormatter } from "@/utilities/Formatter"
import { SpaceObjectCache } from "@/services/SpaceObjectsService"
import { userCache } from "@/services/UsersClient"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
    infoDialog,
    successDialog,
} from "@/services/DialogService"
import { hasPermission } from "@/services/AuthService"

export default defineComponent({
    name: "IssueDetail",
    components: {
        TimeText,
        SpaceObjectLocation,
        IssueLogTimeline,
        IssueRelatedMission,
        UserChip,
        SendRepairMissionDialog,
        SendCheckMissionDialog,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        title.value = `問題檢視：${props.id}`

        const state = reactive({
            issue: null as IssueViewModel | null,
            issueLogAttaches: [] as AttachDTO[],
        })

        const isClosed = computed(
            () => state.issue?.state === IssueState.Closed
        )

        async function loadData() {
            const issue: Partial<IssueViewModel> = await getIssue(props.id)
            issue.spaceObject = undefined
            issue.creator = undefined
            issue.closer = undefined
            state.issue = issue as IssueViewModel
            if (issue.spaceObjectId) {
                await SpaceObjectCache.updateByIds([issue.spaceObjectId])
                issue.spaceObject = SpaceObjectCache.get(issue.spaceObjectId)
            }
            const userIds = [issue.creatorId!]
            if (issue.closerId) userIds.push(issue.closerId)
            await userCache.updateByIds(userIds)
            issue.creator = userCache.get(issue.creatorId!)
            if (issue.closerId) issue.closer = userCache.get(issue.closerId)
            state.issueLogAttaches = await getIssueLogAttachFiles(props.id)
        }

        loadData()

        async function closeIssue() {
            const { button } = await infoDialog(
                "確定要關閉問題？",
                "",
                DialogButtonType.YesNo
            )
            if (button !== ButtonResult.Yes) return
            try {
                await callCloseIssue(props.id)
                successDialog("已成功關閉")
                loadData()
            } catch (error) {
                console.error(error)
                errorDialog("關閉失敗，請聯絡系統維護人員")
            }
        }

        const hasIssueManagePermission = hasPermission("ISSUE_MANAGE")

        return {
            ...toRefs(state),
            timeFormatter,
            timeFromNowFormatter,
            issueStateFormatter,
            loadData,
            closeIssue,
            isClosed,
            hasIssueManagePermission,
        }
    },
})
