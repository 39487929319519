import ky from "ky"
import { accessToken } from "@/services/AuthService"
import { env } from "@/env"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
} from "@/services/DialogService"
import { login } from "@/services/AuthService"
import { buildParms } from "@/utilities/SearchParmsBuilder"
import { SpaceObjectDTO } from "./SpaceObjectsService"
import { UserDTO } from "./UsersClient"
import { blobToSha256 } from "@/utilities/Blob"

const api = ky.create({
    prefixUrl: env.ISSUE_SERVICE,
    timeout: 30000,
    hooks: {
        beforeRequest: [
            (request) => {
                request.headers.set(
                    "Authorization",
                    `Bearer ${accessToken.value}`
                )
            },
        ],
        afterResponse: [
            async (_request, _options, response) => {
                if (response.status === 401) {
                    const { button } = await errorDialog(
                        "身份驗證失敗，請重新登入",
                        undefined,
                        DialogButtonType.YesNo
                    )
                    if (button === ButtonResult.Yes) login()
                }
            },
        ],
    },
})

export enum IssueState {
    Open,
    Closed,
    Repairing,
    ReportRepaired,
    CheckingRepaired,
    ReportChecked,
}

export function issueStateFormatter(state: IssueState) {
    switch (state) {
        case IssueState.Open:
            return "開放"
        case IssueState.Closed:
            return "關閉"
        case IssueState.Repairing:
            return "維修中"
        case IssueState.ReportRepaired:
            return "維修任務完成"
        case IssueState.CheckingRepaired:
            return "查驗中"
        case IssueState.ReportChecked:
            return "查驗任務完成"
        default:
            return "不明"
    }
}

export enum IssueLogType {
    CreatedFromMission,
    Updated,
    RepairMissionSend,
    RepairMissionReported,
    CheckMissionSend,
    CheckMissionReported,
    Closed,
    Comment,
}

export enum IssueMissionRelationType {
    IssueSource,
    Repair,
    Check,
}

export function issueMissionRelationTypeFormatter(
    type: IssueMissionRelationType
) {
    switch (type) {
        case IssueMissionRelationType.IssueSource:
            return "來源任務"
        case IssueMissionRelationType.Repair:
            return "維修任務"
        case IssueMissionRelationType.Check:
            return "查驗任務"
        default:
            return "不明"
    }
}

export interface IssueDTO {
    id: string
    subject: string
    state: IssueState
    createdTime: string
    creatorId: string
    updatedTime: string
    closedTime: string | null
    closerId: string | null
    description: string
    spaceObjectId: string | null
    issueLogs: IssueLogDTO[]
    issueMissionRelations: IssueMissionRelationDTO[]
}

export interface IssueLogDTO {
    id: string
    issueId: string
    createdTime: string
    creatorId: string | null
    type: IssueLogType
    missionId: string | null
    comment: string | null
    attachFileIds: string[]
    pictureIds: string[]
}

export interface IssueMissionRelationDTO {
    issueId: string
    missionId: string
    type: IssueMissionRelationType
    sourceFieldId: string | null
    repairedFieldId: string | null
    checkedFieldId: string | null
    fieldIds: string[]
}

export interface IssueRefProperty {
    spaceObject?: SpaceObjectDTO
    creator?: UserDTO
    closer?: UserDTO
}

export type IssueViewModel = IssueDTO & IssueRefProperty

export enum IssueSortMethod {
    CreatedTime,
    CreatedTimeDesc,
    Location,
}

export interface IssueQuery {
    skip?: number | null
    take?: number | null
    ids?: string[] | null
    keyword?: string | null
    states?: IssueState[] | null
    locationSpaceObjectIds?: string[] | null
    mileageStart?: number | null
    mileageEnd?: number | null
    sort?: IssueSortMethod | null
    createdTimeStart?: string | null
    createdTimeEnd?: string | null
}

/** 查詢問題 */
export function getIssues(query: IssueQuery = {}) {
    return api
        .get("api/Issues", {
            searchParams: buildParms(query as {}),
        })
        .json<{
            total: number
            items: IssueDTO[]
        }>()
}

export function updateIssueTrackingTableFile(
    file: File,
    query: IssueQuery = {}
) {
    const formData = new FormData()
    formData.append("file", file)
    return api.post("api/IssueTrackingTable/UpdateIssueTrackingTableFile", {
        searchParams: buildParms(query as {}),
        body: formData,
    })
}

/** 取得問題 */
export function getIssue(id: string) {
    return api.get(`api/Issues/${id}`).json<IssueDTO>()
}

/** 新增問題留言 */
export function postIssueComment(
    id: string,
    input: {
        comment: string
        uploadPictureIds: string[]
        uploadFileIds: string[]
    }
) {
    return api.post(`api/Issues/${id}/Comments`, {
        json: input,
    })
}

/** 上傳夾檔 */
export async function uploadAttach(id: string, file: File) {
    const sha256 = await blobToSha256(file)
    const formData = new FormData()
    formData.append("file", file)
    formData.append("sha256", sha256)
    return await api.post(`api/Attaches/${id}`, {
        body: formData,
    })
}

/** 下載夾檔 */
export function getIssueLogAttachFile(
    issueId: string,
    logId: string,
    attachId: string
) {
    return api.get(
        `api/Issues/${issueId}/IssueLogs/${logId}/Attaches/${attachId}`
    )
}

export interface AttachDTO {
    id: string
    fileName: string
}

export function getIssueLogAttachFiles(id: string) {
    return api.get(`api/Issues/${id}/IssueLogs/-/Attaches`).json<AttachDTO[]>()
}

/** 關閉任務 */
export function closeIssue(id: string) {
    return api.post(`api/Issues/${id}/Close`)
}

/** 發送維修任務 */
export function sendRepairMission(
    id: string,
    input: {
        subject: string
        startTime: string
        overdueTime: string
        description: string
        managerIds: string[]
        assigneeIds: string[]
        cooperatorIds: string[]
    }
) {
    return api.post(`api/Issues/${id}/SendReapirMission`, {
        json: input,
    })
}

/** 發送查驗任務 */
export function sendCheckMission(
    id: string,
    input: {
        subject: string
        startTime: string
        overdueTime: string
        description: string
        managerIds: string[]
        assigneeIds: string[]
        cooperatorIds: string[]
    }
) {
    return api.post(`api/Issues/${id}/SendCheckMission`, {
        json: input,
    })
}
