





















import {
    defineComponent,
    watchEffect,
    reactive,
    computed,
} from "@vue/composition-api"
import IssueAddComment from "./IssueAddComment.vue"
import IssueLogTimelineItem from "./IssueLogTimelineItem.vue"
import { AttachDTO, IssueLogDTO } from "@/services/IssueService"
import { timeFormatter } from "@/utilities/Formatter"
import { userCache } from "@/services/UsersClient"
export default defineComponent({
    name: "IssueLogTimeline",
    components: {
        IssueLogTimelineItem,
        IssueAddComment,
    },
    props: {
        issueId: {
            type: String,
            required: true,
        },
        logs: {
            type: Array as () => IssueLogDTO[],
            required: true,
        },
        attaches: {
            type: Array as () => AttachDTO[],
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            userCacheUpdateTracker: 1,
        })
        watchEffect(async () => {
            await userCache.updateByIds(
                props.logs
                    .map((i) => i.creatorId)
                    .filter((id) => !!id) as string[]
            )
            state.userCacheUpdateTracker += 1
        })

        const logWithRef = computed(() => {
            state.userCacheUpdateTracker

            return props.logs.map((log) => ({
                log,
                user: log.creatorId
                    ? userCache.get(log.creatorId) ?? null
                    : null,
                attaches: props.attaches.filter((a) =>
                    log.attachFileIds.includes(a.id)
                ),
            }))
        })

        function newCommentAdded() {
            emit("newCommentAdded")
        }

        return {
            timeFormatter,
            logWithRef,
            newCommentAdded,
        }
    },
})
