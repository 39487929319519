








































import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import {
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    setMessage,
    warningDialog,
} from "@/services/DialogService"
import { postIssueComment, uploadAttach } from "@/services/IssueService"
import { v4 as uuidv4 } from "uuid"
export default defineComponent({
    name: "IssueAddComment",
    props: {
        issueId: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            comment: "",
            attaches: [] as File[],
        })
        async function save() {
            if (!state.comment) {
                warningDialog("請輸入內容")
                return
            }
            try {
                infoDialog("送出中", "請稍後", DialogButtonType.None)
                const pictureExts = [".jpg", ".jpeg", ".png"]
                const attaches = state.attaches.map((a) => ({
                    id: uuidv4(),
                    file: a,
                }))
                const pictures = attaches.filter((a) =>
                    pictureExts.some((ext) => a.file.name.endsWith(ext))
                )
                const files = attaches.filter((a) => !pictures.includes(a))

                await postIssueComment(props.issueId, {
                    comment: state.comment,
                    uploadFileIds: files.map((f) => f.id),
                    uploadPictureIds: pictures.map((p) => p.id),
                })
                for (let index = 0; index < attaches.length; index++) {
                    const attach = attaches[index]
                    setMessage(`正在傳輸檔案 ${attach.file.name}`)
                    await uploadAttach(attach.id, attach.file)
                }
                closeDialog()
                emit("added")
                state.comment = ""
                state.attaches = []
            } catch (error) {
                console.error(error)
                errorDialog("新增留言失敗，請聯絡系統維護人員")
            }
        }

        const acceptTypes = [
            "text/csv",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.oasis.opendocument.spreadsheet",
            "application/vnd.oasis.opendocument.presentation",
            "image/jpeg",
            "image/png",
        ].join(", ")

        return {
            ...toRefs(state),
            save,
            acceptTypes,
        }
    },
})
