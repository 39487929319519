











import { computed, defineComponent } from "@vue/composition-api"
import dayjs from "dayjs"
import { now } from "@/services/TimeService"
import { timeFormatter } from "@/utilities/Formatter"
export default defineComponent({
    name: "TimeText",
    props: {
        time: [String, Number],
        pastClass: String,
        futureClass: String,
        format: {
            type: String,
            default: "YYYY-MM-DD HH:mm",
        },
        pastToolTip: String,
        futureToolTip: String,
        toolTip: String,
    },
    setup(props) {
        const isPast = computed(() => {
            const time = dayjs(props.time)
            if (!props.time || !time.isValid()) return null
            return time.valueOf() < now.value.valueOf()
        })

        const styleClass = computed(() => {
            return isPast.value === null
                ? ""
                : !!isPast.value
                ? props.pastClass ?? ""
                : props.futureClass ?? ""
        })

        const toolTipFormat = computed(() => {
            return isPast.value === null
                ? ""
                : isPast.value && props.pastToolTip
                ? props.pastToolTip
                : !isPast.value && props.futureToolTip
                ? props.futureToolTip
                : props.toolTip
                ? props.toolTip
                : ""
        })
        return {
            styleClass,
            timeFormatter,
            toolTipFormat,
        }
    },
})
