


































































































































import {
    IssueMissionRelationDTO,
    issueMissionRelationTypeFormatter,
} from "@/services/IssueService"
import TimeText from "@/components/TimeText.vue"
import MissionFieldInput from "@/views/Mission/MissionFieldInput.vue"
import FieldPictureViewer from "@/views/Mission/FieldPictureViewer.vue"
import MissionFieldMemoInput from "@/views/Mission/MissionFieldMemoInput.vue"
import {
    MissionDetialModel,
    missionStateFormatter,
} from "@/services/MissionsService"
import { computed, defineComponent, provide } from "@vue/composition-api"
export default defineComponent({
    name: "IssueRelatedMission",
    components: {
        TimeText,
        MissionFieldInput,
        FieldPictureViewer,
        MissionFieldMemoInput,
    },
    props: {
        relation: {
            type: Object as () => IssueMissionRelationDTO,
            required: true,
        },
    },
    setup(props) {
        const missionViewModel = new MissionDetialModel(
            props.relation.missionId
        )
        provide(MissionDetialModel.name, missionViewModel)
        const subject = computed(() => missionViewModel.mission.value?.subject)
        const relatedFields = computed(
            () =>
                missionViewModel.missionFields.value?.filter((f) =>
                    props.relation.fieldIds.includes(f.id)
                ) ?? []
        )
        return {
            subject,
            relatedFields,
            missionViewModel,
            issueMissionRelationTypeFormatter,
            missionStateFormatter,
        }
    },
})
