


















import { blobToDataURL } from "@/utilities/Blob"
import {
    defineComponent,
    PropType,
    reactive,
    toRefs,
} from "@vue/composition-api"
import contentDisposition from "content-disposition"
import saveAs from "file-saver"
import { ResponsePromise } from "ky"
export default defineComponent({
    props: {
        maxHeight: Number,
        maxWidth: Number,
        id: {
            type: String,
            required: true,
        },
        downloadPictureMethod: {
            type: Function as PropType<(id: string) => ResponsePromise>,
            required: true,
        },
        displayFileName: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const state = reactive({
            dataUrl: "",
            fileName: "",
            loading: true,
            hasError: false,
        })

        async function load() {
            const response = await props.downloadPictureMethod(props.id)
            if (response.status == 200) {
                state.fileName = contentDisposition.parse(
                    response.headers.get("content-disposition")!
                ).parameters.filename

                const blob = await response.blob()
                state.loading = false
                state.dataUrl = await blobToDataURL(blob)
            } else {
                state.hasError = true
                state.loading = false
            }
        }

        load()

        function save() {
            if (state.dataUrl && state.fileName)
                saveAs(state.dataUrl, state.fileName)
        }

        return {
            ...toRefs(state),
            save,
        }
    },
})
