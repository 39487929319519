














import { computed, defineComponent, inject } from "@vue/composition-api"
import PictureViewer from "@/components/PictureViewer.vue"
import {
    getMissionFieldPicture,
    MissionDetialModel,
    MissionFieldViewModel,
} from "@/services/MissionsService"
import lodash from "lodash"

export default defineComponent({
    name: "FieldPictureViewer",
    components: {
        PictureViewer,
    },
    props: {
        field: {
            type: Object as () => MissionFieldViewModel,
            required: true,
        },
        canMarkRemovePictures: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const pictureIds = computed(() => props.field.latestRecord?.pictureIds)
        const removeIds = computed(() => props.field.removePictureIds)
        const hasPicture = computed(() => pictureIds.value?.length)

        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )!

        function downloadPictureMethod(id: string) {
            return getMissionFieldPicture(missionDetial.id, props.field.id, id)
        }

        function setRemoveMark(payload: { id: string; removed: boolean }) {
            let removeIds = lodash(props.field.removePictureIds)
            if (payload.removed) {
                removeIds = removeIds.push(payload.id)
            } else {
                removeIds = removeIds.remove(payload.id)
            }
            missionDetial.setFieldRemovePictureIds(
                props.field.id,
                removeIds.value()
            )
        }

        return {
            pictureIds,
            downloadPictureMethod,
            setRemoveMark,
            removeIds,
            hasPicture,
        }
    },
})
