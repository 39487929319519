




































































































































































import { defineComponent } from "@vue/composition-api"
import TimeText from "@/components/TimeText.vue"
import UserChip from "@/components/UserChip.vue"
import PictureViewer from "@/components/PictureViewer.vue"
import {
    IssueLogDTO,
    IssueLogType,
    getIssueLogAttachFile,
    AttachDTO,
} from "@/services/IssueService"
import { UserDTO } from "@/services/UsersClient"
import { saveAs } from "file-saver"
import contentDisposition from "content-disposition"
export default defineComponent({
    name: "IssueLogTimelineItem",
    components: {
        TimeText,
        UserChip,
        PictureViewer,
    },
    props: {
        item: {
            type: Object as () => {
                log: IssueLogDTO
                user: UserDTO | null
                attaches: AttachDTO[]
            },
            required: true,
        },
    },
    setup(props) {
        function getAttach(id: string) {
            return getIssueLogAttachFile(
                props.item.log.issueId,
                props.item.log.id,
                id
            )
        }

        async function downloadAttach(id: string) {
            const response = await getAttach(id)
            const blob = await response.blob()
            const name = contentDisposition.parse(
                response.headers.get("content-disposition")!
            ).parameters.filename
            saveAs(blob, name)
        }
        return {
            IssueLogType,
            getAttach,
            downloadAttach,
        }
    },
})
