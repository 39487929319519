









































































import { defineComponent, reactive, toRefs, watch } from "@vue/composition-api"
import UserPagingSelect from "@/components/UserPagingSelect.vue"
import DateTimeInput from "@/components/DateTimeInput.vue"
import { sendCheckMission } from "@/services/IssueService"
import dayjs from "dayjs"
import { errorDialog, successDialog } from "@/services/DialogService"
import { env } from "@/env"

export default defineComponent({
    name: "SendCheckMissionDialog",
    components: {
        UserPagingSelect,
        DateTimeInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            dialog: false,
            subject: "查驗任務",
            startTime: dayjs().startOf("minutes").toISOString(),
            overdueTime: dayjs().startOf("day").add(7, "day").toISOString(),
            description: "未填寫描述",
            managerIds: [] as string[],
            assigneeIds: [] as string[],
            cooperatorIds: [] as string[],
        })

        watch(
            () => state.dialog,
            (dialog) => {
                if (!dialog) return
                state.subject = "查驗任務"
                state.startTime = dayjs().startOf("minutes").toISOString()
                state.overdueTime = dayjs()
                    .startOf("day")
                    .add(7, "day")
                    .toISOString()
                state.description = "未填寫描述"
                state.managerIds = []
                state.assigneeIds = []
                state.cooperatorIds = []
            }
        )

        async function submit() {
            try {
                await sendCheckMission(props.id, {
                    subject: state.subject,
                    startTime: state.startTime,
                    overdueTime: state.overdueTime,
                    description: state.description,
                    managerIds: state.managerIds,
                    assigneeIds: state.assigneeIds,
                    cooperatorIds: state.cooperatorIds,
                })
                state.dialog = false
                successDialog("發送查驗任務成功")
                emit("sent")
            } catch (error) {
                errorDialog("發送查驗任務發生錯誤，請聯絡系統維護人員")
            }
            state.dialog = false
        }

        function close() {
            state.dialog = false
        }
        return {
            ...toRefs(state),
            submit,
            close
        }
    },
})
