










































import {
    defineComponent,
    reactive,
    toRefs,
    PropType,
} from "@vue/composition-api"
import Picture from "./Picture.vue"
import { saveAs } from "file-saver"
import { ResponsePromise } from "ky"
interface Picture {
    id: string
    dataUrl: string | null
    fileName: string | null
    markToRemove: boolean
}
export default defineComponent({
    name: "PictureViewer",
    components: {
        Picture,
    },
    props: {
        title: String,
        pictureIds: {
            type: Array as () => string[],
            required: true,
        },
        downloadPictureMethod: {
            type: Function as PropType<(id: string) => ResponsePromise>,
            required: true,
        },
        removePictureIds: {
            type: Array as () => string[],
        },
        canMarkRemovePictures: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const state = reactive({
            dialog: false,
        })

        function trigger() {
            state.dialog = true
        }

        function save(picture: Picture) {
            if (picture.dataUrl && picture.fileName)
                saveAs(picture.dataUrl, picture.fileName)
        }

        function switchMarkToRemove(checked: boolean, pictureId: string) {
            emit("set-remove-mark", {
                id: pictureId,
                removed: checked,
            })
        }
        return {
            ...toRefs(state),
            trigger,
            save,
            switchMarkToRemove,
        }
    },
})
