































import {
    MissionDetialModel,
    MissionFieldViewModel,
} from "@/services/MissionsService"
import {
    computed,
    defineComponent,
    inject,
    reactive,
    toRefs,
} from "@vue/composition-api"
export default defineComponent({
    name: "MissionFieldMemoInput",
    props: {
        field: {
            type: Object as () => MissionFieldViewModel,
            required: true,
        },
    },
    setup(props) {
        const state = reactive({
            showDialog: false,
            input: props.field.memo,
        })
        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )!

        const canFill = computed(
            () => missionDetial.mission.value?.canFill ?? false
        )

        function save() {
            missionDetial.setFieldMemo(props.field.id, state.input)
            state.showDialog = false
        }

        return {
            ...toRefs(state),
            canFill,
            save,
        }
    },
})
