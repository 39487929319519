

































































import { hasPermission } from "@/services/AuthService"
import {
    MissionDetialModel,
    MissionFieldViewModel,
} from "@/services/MissionsService"
import { FieldType } from "@/services/MissionTemplatesService"
import {
    computed,
    defineComponent,
    inject,
    reactive,
    toRefs,
} from "@vue/composition-api"
export default defineComponent({
    name: "MissionFieldInput",
    props: {
        field: {
            type: Object as () => MissionFieldViewModel,
            required: true,
        },
        readonly: Boolean,
    },
    setup(props) {
        const missionDetial = inject<MissionDetialModel>(
            MissionDetialModel.name
        )

        const state = reactive({
            numberErr: false,
        })

        const value = computed({
            get: () => {
                switch (props.field.type) {
                    case FieldType.Number:
                        props.field.value?.toString() ?? ""
                    default:
                        return props.field.value
                }
            },
            set: (value) => {
                switch (props.field.type) {
                    case FieldType.Number:
                        const n = parseFloat(value as string)
                        if (isNaN(n)) {
                            state.numberErr = true
                            return
                        } else {
                            missionDetial?.setFieldValue(props.field.id, n)
                            state.numberErr = false
                        }
                        break

                    default:
                        missionDetial?.setFieldValue(props.field.id, value)
                        break
                }
            },
        })

        const canFill = computed(
            () =>
                !props.readonly &&
                (missionDetial?.mission.value?.canFill ?? false) &&
                hasPermission("WEB_FILL_MISSION")
        )

        return {
            ...toRefs(state),
            value,
            canFill,
            FieldType,
        }
    },
})
