var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{attrs:{"small":"","fill-dot":""}},[_c('v-card',[_c('v-card-text',[_c('v-container',{staticClass:"py-1 px-2"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('TimeText',{attrs:{"time":_vm.item.log.createdTime,"format":"YYYY-MM-DD HH:mm"}})],1),_c('v-col',{staticClass:"pa-0"},[(
                                _vm.item.log.type ===
                                _vm.IssueLogType.CreatedFromMission
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 關閉任務"),_c('v-btn',{attrs:{"text":"","small":"","to":("/mission/" + (_vm.item.log.missionId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.log.missionId)+" ")]),_vm._v("後建立本問題 ")]:(
                                _vm.item.log.type === _vm.IssueLogType.Updated
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 更新資訊 ")]:(
                                _vm.item.log.type ===
                                _vm.IssueLogType.RepairMissionSend
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 發出維修任務 "),_c('v-btn',{attrs:{"text":"","small":"","to":("/mission/" + (_vm.item.log.missionId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.log.missionId)+" ")])]:(
                                _vm.item.log.type ===
                                _vm.IssueLogType.RepairMissionReported
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 回報維修任務 "),_c('v-btn',{attrs:{"text":"","small":"","to":("/mission/" + (_vm.item.log.missionId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.log.missionId)+" ")]),_vm._v(" 完成 ")]:(
                                _vm.item.log.type ===
                                _vm.IssueLogType.CheckMissionSend
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 發出查驗任務 "),_c('v-btn',{attrs:{"text":"","small":"","to":("/mission/" + (_vm.item.log.missionId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.log.missionId)+" ")])]:(
                                _vm.item.log.type ===
                                _vm.IssueLogType.CheckMissionReported
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 回報查驗任務 "),_c('v-btn',{attrs:{"text":"","small":"","to":("/mission/" + (_vm.item.log.missionId)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.log.missionId)+" ")]),_vm._v(" 完成 ")]:(
                                _vm.item.log.type === _vm.IssueLogType.Closed
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_vm._v(" 關閉本問題 ")]:(
                                _vm.item.log.type === _vm.IssueLogType.Comment
                            )?[_c('UserChip',{attrs:{"user":_vm.item.user}}),_c('span',[_vm._v(" 留言")]),_c('v-alert',{staticClass:"mt-2 whitespace-pre-wrap",attrs:{"dense":"","border":"left","colored-border":"","color":"primary","elevation":"1"}},[_vm._v(" "+_vm._s(_vm.item.log.comment)+" ")]),_vm._l((_vm.item.attaches),function(attach){return _c('v-chip',{key:attach.id,attrs:{"color":"primary","label":"","small":""},on:{"click":function($event){return _vm.downloadAttach(attach.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file")]),_vm._v(" "+_vm._s(attach.fileName)+" ")],1)}),(
                                    _vm.item.log.pictureIds &&
                                    _vm.item.log.pictureIds.length
                                )?_c('PictureViewer',{attrs:{"pictureIds":_vm.item.log.pictureIds,"downloadPictureMethod":_vm.getAttach},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var trigger = ref.trigger;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":trigger}},[_c('v-icon',[_vm._v("mdi-image")])],1)]}}],null,false,2514445687)}):_vm._e()]:_vm._e()],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }