












import { defineComponent, computed } from "@vue/composition-api"
import ChipWithTooltip from "@/components/ChipWithTooltip.vue"
import { SpaceObjectDTO } from "@/services/SpaceObjectsService"
export default defineComponent({
    name: "SpaceObjectLocation",
    components: {
        ChipWithTooltip,
    },
    props: {
        spaceObject: {
            type: Object as () => SpaceObjectDTO,
            required: true,
        },
    },
    setup(props, { emit }) {
        const ans = computed(() =>
            props.spaceObject.spaceObjectPath.idPath.map((id, index) => ({
                id,
                name: props.spaceObject.spaceObjectPath.namePath[index],
                number: props.spaceObject.spaceObjectPath.numberPath[index],
            }))
        )

        function click(id: string) {
            emit("click", id)
        }
        return {
            ans,
            click,
        }
    },
})
